import React from "react";
import PropTypes from "prop-types";
import "./Button.css";

const Button = ({ text, url, color, onClick, target, type }) => {
  return (
    <div onClick={onClick} className="button-container">
      <a
        href={url}
        target={target ? "_blank" : "_self"}
        rel={target ? "noopener noreferrer" : null}
        className={`button ${color} ${type}`}
      >
        <span>{text}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13px"
          height="10px"
          viewBox="0 0 13 10"
        >
          <path
            d="M1,5 L11,5"
            stroke="#000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <polyline
            points="8 1 12 5 8 9"
            stroke="#000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></polyline>
        </svg>
      </a>
    </div>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default Button;
