import React, { useState, useEffect } from "react";
import "./Banner.css";
import bannerGif from "../../assets/img/hero-animation.gif";
import bannerBackground from "../../assets/img/Hero-Banner-Background.jpg";
import tabletGif from "../../assets/img/hero-animation-tablet.gif";
import mobileGif from "../../assets/img/hero-animation-mobile.gif";

export const Banner = () => {
  // Set mobile & tablet states so different GIF can be shown
  const [isTablet, setIsTablet] = useState(
    window.innerWidth < 1000 && window.innerWidth > 700
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth < 1000 && window.innerWidth > 700);
      setIsMobile(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section
      className="banner d-flex align-items-center justify-content-center content"
      id="home"
    >
      <img
        style={{ width: "100%" }}
        className="background"
        src={bannerBackground}
        alt="Background"
      />
      {isTablet ? (
        <img src={tabletGif} alt="GirlAtLaptop" className="gif" />
      ) : isMobile ? (
        <img src={mobileGif} alt="GirlAtLaptop" className="gif" />
      ) : (
        <img src={bannerGif} alt="GirlAtLaptop" className="gif" />
      )}
    </section>
  );
};
