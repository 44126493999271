import "./emailConfirmationMessage.css";
import { Col } from "react-bootstrap";
import envelopeGif from "../../assets/img/envelope-hearts.gif";

export const EmailConfirmationMessage = () => {
  return (
    <div className="message-sent-container">
      <Col>
        <div className="message-sent-content">
          <div className="envelope-hearts">
            <img className="envelope" src={envelopeGif} alt="envelope"></img>
          </div>
          <span className="message-sent-text">
            <h5>Message Sent</h5>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
            </svg>{" "}
          </span>
        </div>
      </Col>
    </div>
  );
};
