import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import Button from "../Button/Button";

let currentOpenSetter = null; // Static variable to keep track of the open overlay setter

export const ProjectCard = ({
  title,
  skills,
  description,
  imgUrl,
  codeUrl,
  projectUrl,
}) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const projectRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Close overlay if click outside of project
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMobile &&
        isOverlayVisible &&
        projectRef.current &&
        !projectRef.current.contains(event.target)
      ) {
        setIsOverlayVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, isOverlayVisible]);

  // Make overlay visible on hover
  const handleMouseEnter = () => {
    setIsOverlayVisible(true);
  };

  // Make overlay dissapear when not hovering
  const handleMouseLeave = () => {
    setIsOverlayVisible(false);
  };

  // Only have one overlay at a time visible
  const openOverlay = () => {
    if (currentOpenSetter && currentOpenSetter !== setIsOverlayVisible) {
      currentOpenSetter(false); // Close any currently open overlay
    }
    setIsOverlayVisible(true);
    currentOpenSetter = setIsOverlayVisible; // Update the static variable
  };
  const closeOverlay = () => {
    setIsOverlayVisible(false);
    if (currentOpenSetter === setIsOverlayVisible) {
      currentOpenSetter = null;
    }
  };

  // Close overlay on click for mobile
  const handleProjectClick = () => {
    if (isMobile) {
      isOverlayVisible ? closeOverlay() : openOverlay();
    }
  };

  return (
    <Col xs={12} md={8} lg={6} onClick={handleProjectClick}>
      <div
        className="project"
        ref={projectRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img src={imgUrl} alt="project-img" className="project-image" />
        <div
          className={`project-overlay ${
            isOverlayVisible ? "show-project" : ""
          }`}
        >
          <div
            className={`project-overlay-content ${
              isOverlayVisible ? "show-content" : ""
            }`}
          >
            {isMobile && (
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => setIsOverlayVisible(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
            <h2>{title}</h2>
            <div className="skills-row">
              {skills.map((skill, index) => (
                <img
                  key={skill.id || index}
                  src={skill.image}
                  alt={skill.name}
                  className="skill-image"
                />
              ))}
            </div>
            <p>{description}</p>
            <div className="d-flex flex-row">
              {codeUrl && (
                <Button
                  text="View Code"
                  url={codeUrl}
                  color="pink"
                  target={true}
                  type="project-button"
                />
              )}
              <Button
                text="View Project"
                url={projectUrl}
                color="pink"
                target={true}
                type="project-button"
              />
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};
