import React, { Component } from "react";
import PropTypes from "prop-types";
import "../Button/Button.css";

class EmailSenderButton extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  };

  render() {
    const { text, color } = this.props;

    return (
      <div className={"button-container"}>
        <button className={`button ${color}`}>
          <span>{text}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13px"
            height="10px"
            viewBox="0 0 13 10"
          >
            <path
              d="M1,5 L11,5"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <polyline
              points="8 1 12 5 8 9"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></polyline>
          </svg>
        </button>
      </div>
    );
  }
}

export default EmailSenderButton;
