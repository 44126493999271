const NavBarLogo = () => (
  <svg
    className="logo"
    width="100%"
    height="100%"
    viewBox="0 0 260 80"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
  >
    <g transform="matrix(0.135917,0,0,0.124889,-1.73597,-58.8425)">
      <rect
        x="104.137"
        y="514.997"
        width="1730.2"
        height="485.003"
        style={{ fill: "white", stroke: "black", strokeWidth: "7.66px" }}
      />
    </g>
    <g transform="matrix(0.15725,0,0,0.181158,-23.3749,-105.457)">
      <rect
        x="276.675"
        y="890.364"
        width="1397.36"
        height="109.636"
        style={{ fill: "rgb(227,246,255)" }}
      />
    </g>
    <g transform="matrix(1,0,0,1,-123.231,-540.718)">
      <text
        x="142.183px"
        y="586.109px"
        style={{
          fontFamily: "'PlayfairDisplay-Bold', 'Playfair Display'",
          fontWeight: 700,
          fontSize: "36px",
          fill: "rgb(22,22,22)",
        }}
      >
        Emma
        <tspan x="248.635px" y="586.109px">
          {" "}
        </tspan>
        Sm
        <tspan x="310.231px" y="586.109px">
          y
        </tspan>
        th
      </text>
    </g>
    <g transform="matrix(1,0,0,1,-300.117,-882.159)">
      <text
        x="326.938px"
        y="953.036px"
        style={{ fontFamily: "'Verdana', sans-serif", fontSize: "12px" }}
      >
        F
        <tspan
          x="338.741px 351.787px 362.459px 373.453px 388.626px 400.429px 412.373px 423.56px 431.379px 444.225px 455.413px 467.216px 478.404px 488.567px 501.612px 512.448px 523.636px"
          y="953.036px 953.036px 953.036px 953.036px 953.036px 953.036px 953.036px 953.036px 953.036px 953.036px 953.036px 953.036px 953.036px 953.036px 953.036px 953.036px 953.036px"
        >
          RONTEND DEVELOPER
        </tspan>
      </text>
    </g>
  </svg>
);

export default NavBarLogo;
