import { Container, Row, Col } from "react-bootstrap";
import "./About.css";
import "react-multi-carousel/lib/styles.css";
import aboutImage from "../../assets/img/About-Me-Image.jpg";

export const About = () => {
  return (
    <section className="about" id="about">
      <Container>
        <div className="about-box">
          <Row className="align-items-center">
            <h1>About</h1>
            <Col lg={6} md={12} style={{ padding: 0 }}>
              <p>
                I am a{" "}
                <span className="key-words">committed Frontend Developer</span>{" "}
                based in Cumbria with a focus on{" "}
                <span className="key-words">Website Development</span>. I
                specialise in <span className="key-words">problem-solving</span>
                , fostering creativity, and maintaining a keen sense of
                commercial awareness. My main goal is to develop{" "}
                <span className="key-words">
                  innovative and reliable software solutions
                </span>{" "}
                that exceed the project requirements.
                <br></br> <br></br>
                With a background as a{" "}
                <span className="key-words">Brand & General Manager</span>, I
                bring a unique skill set that enhances my ability to{" "}
                <span className="key-words">comprehend client needs</span> and
                <span className="key-words"> navigate challenges</span> in the
                development process.
                <br></br> <br></br>
                Beyond coding and development, I love to practice yoga, read a
                good fantasy book, spend time with my cat and explore the
                outdoors.
              </p>
            </Col>
            <Col lg={6} md={12}>
              <img src={aboutImage} alt="Emma" />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};
