import React, { useState, useEffect, useRef } from "react";
import "./NavBar.css";
import Button from "../Button/Button";
import { Navbar, Nav, Container } from "react-bootstrap";
import NavBarLogo from "./NavBarLogo";

const CVUrl = process.env.PUBLIC_URL + "/CV-Emma-Smyth.pdf";

export const NavBar = () => {
  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastMouseY, setLastMouseY] = useState(window.innerHeight);
  const MOUSE_THRESHOLD = 150; //threshold for moving mouse upwards
  const lastScrollY = useRef(window.scrollY);

  // Scroll event listener
  const SCROLL_THRESHOLD = 700; // Threshold of scrolling down before nav dissapears

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (
      currentScrollY > SCROLL_THRESHOLD &&
      lastScrollY.current < currentScrollY
    ) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
    lastScrollY.current = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Mouse move event listener
  useEffect(() => {
    const handleMouseMove = (e) => {
      // Check if the vertical mouse movement exceeds the threshold
      if (lastMouseY - e.clientY > MOUSE_THRESHOLD) {
        setShowNavbar(true);
        setLastMouseY(e.clientY);
      } else if (e.clientY - lastMouseY > MOUSE_THRESHOLD) {
        setLastMouseY(e.clientY);
      }
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [lastMouseY]);

  // monitor if screen size is less than 992px
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Prevent default anchor link behavior for scrolling when navLink clicked
  const handleNavClick = (e, targetId) => {
    e.preventDefault();

    // Set up scrolling on click of navLinks
    const targetSection = document.getElementById(targetId);
    if (!targetSection) return;

    let offset = 0; // Default offset for desktop for scrolling on click
    const screenWidth = window.innerWidth;
    if (screenWidth > 450 && screenWidth <= 1000) {
      offset = 50; // Default offset for tablet
    } else if (screenWidth <= 450) {
      offset = 110; // Default offset for mobile
    }

    const top =
      targetSection.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({ top, behavior: "smooth" });

    if (isMobile) {
      setExpanded(false);
    }
  };

  return (
    <Navbar
      expand="lg"
      expanded={expanded}
      className={showNavbar ? "" : "hidden-navbar"}
    >
      <Container className="full-navbar">
        <Navbar.Brand href="#home">
          <NavBarLogo />
        </Navbar.Brand>
        <Navbar.Toggle
          className="custom-navbar-toggler"
          aria-controls="basic-navbar-nav"
          aria-expanded={expanded}
          onClick={() => setExpanded(!expanded)}
        >
          <span className="navbar-toggler-icon"></span>
          <div className="circle"></div>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              href="#home"
              className="navbar-link"
              onClick={(e) => handleNavClick(e, "home")}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="#skills"
              className="navbar-link"
              onClick={(e) => handleNavClick(e, "skills")}
            >
              Skills
            </Nav.Link>
            <Nav.Link
              href="#projects"
              className="navbar-link"
              onClick={(e) => handleNavClick(e, "projects")}
            >
              Projects
            </Nav.Link>
            <Nav.Link
              href="#about"
              className="navbar-link"
              onClick={(e) => handleNavClick(e, "about")}
            >
              About
            </Nav.Link>
            <Nav.Link
              className="navbar-link"
              href={CVUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => isMobile && setExpanded(false)}
            >
              CV
            </Nav.Link>
          </Nav>
          <Button
            text="Get In Touch"
            url="#contact"
            color="pink"
            onClick={(e) => handleNavClick(e, "contact")}
            target={false}
          />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
