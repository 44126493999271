import "./Contact.css";
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import EmailSenderButton from "./emailSenderButton";
import { EmailConfirmationMessage } from "./emailConfirmationMessage";
import GetInTouchGif from "../../assets/img/Get-In-Touch.gif";

export const Contact = () => {
  // Set up object for collecting values typed into form
  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  // Set up States
  let [emptyFields, setEmptyFields] = useState([]);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(null);
  const [touched, setTouched] = useState(false);

  // Function to reset the form states
  const resetForm = () => {
    setFormDetails({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    });
    setEmailSent(false);
    setError(null);
    setTouched(false);
    setEmptyFields([]);
  };

  // Reset form if field click & email sent already
  const handleFocus = () => {
    if (emailSent) {
      resetForm();
    }
  };

  // useEffect to update emptyFields when formDetails changes
  useEffect(() => {
    setEmptyFields(
      Object.keys(formDetails).filter((field) => formDetails[field] === "")
    );
  }, [formDetails]);

  // Validate form
  const handleFormUpdate = (field, value) => {
    setTouched(true);
    setFormDetails({
      ...formDetails,
      [field]: value,
    });

    // If field is within emptyFields array, remove from array once user starts to type in it
    if (emptyFields.includes(field)) {
      setEmptyFields(emptyFields.filter((f) => f !== field));
    }
  };

  // Send form data
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    // Update touched state to true when the form is submitted
    setTouched(true);

    // Check if any fields are empty
    if (emptyFields.length > 0) {
      setError("Please fill in all the required fields.");
    } else {
      // Prepare data for Netlify
      const myForm = e.target;
      const formData = new FormData(myForm);

      // Send the form data using fetch
      try {
        const response = await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams(formData).toString(),
        });

        // Form submission successful
        if (response.ok) {
          setEmailSent(true);
          setError(null);
          // Handle errors
        } else {
          console.error("Form submission error:", response.statusText);
          setError("An error has occurred, please try again later.");
        }
      } catch (error) {
        console.error("Form submission exception:", error);
        setError("An error has occurred, please try again later.");
      }
    }
  };

  return (
    <section className="contact" id="contact">
      <Container>
        <div>
          <Row className="align-items-center">
            <Col size={12} md={6}>
              <img src={GetInTouchGif} alt="Contact Us" />
            </Col>
            <Col md={6}>
              <h1> Get In Touch</h1>

              {/* Re-set EmailSent state, so button re-appears */}
              <form name="contact" onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="contact" />
                <Row>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      name="firstName"
                      type="text"
                      value={formDetails.firstName}
                      placeholder="First Name"
                      // Send form data to formDetails
                      onChange={(e) =>
                        handleFormUpdate("firstName", e.target.value)
                      }
                      onFocus={handleFocus}
                      // If field is left empty, apply error class
                      className={
                        touched && emptyFields.includes("firstName")
                          ? "error"
                          : ""
                      }
                    />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      name="lastName"
                      type="text"
                      value={formDetails.lastName}
                      placeholder="Last Name"
                      onFocus={handleFocus}
                      // Send form data to formDetails
                      onChange={(e) =>
                        handleFormUpdate("lastName", e.target.value)
                      }
                      // If field is left empty, apply error class
                      className={
                        touched && emptyFields.includes("lastName")
                          ? "error"
                          : ""
                      }
                    />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      name="email"
                      type="email"
                      value={formDetails.email}
                      placeholder="Email Address"
                      // Send form data to formDetails
                      onChange={(e) =>
                        handleFormUpdate("email", e.target.value)
                      }
                      onFocus={handleFocus}
                      // If field is left empty, apply error class
                      className={
                        touched && emptyFields.includes("email") ? "error" : ""
                      }
                    />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      name="tel"
                      type="tel"
                      value={formDetails.phone}
                      placeholder="Phone Number"
                      // Send form data to formDetails
                      onChange={(e) =>
                        handleFormUpdate("phone", e.target.value)
                      }
                      onFocus={handleFocus}
                      // If field is left empty, apply error class
                      className={
                        touched && emptyFields.includes("phone") ? "error" : ""
                      }
                    />
                  </Col>
                  <Col size={12} className="px-1">
                    <textarea
                      rows="6"
                      name="message"
                      value={formDetails.message}
                      placeholder="Message"
                      // Send form data to formDetails
                      onChange={(e) =>
                        handleFormUpdate("message", e.target.value)
                      }
                      onFocus={handleFocus}
                      // If field is left empty, apply error class
                      className={
                        touched && emptyFields.includes("message")
                          ? "error"
                          : ""
                      }
                    />
                    <div className="error-message">
                      {/* If there are any empty fields, show the below message, if there has been an error, display the error message. */}
                      {touched &&
                        emptyFields.length > 0 &&
                        "The highlighted fields are required. "}
                      {error && error}
                    </div>

                    {/* Display button if message not yet sent, or confirmation message if message sent */}
                    <div>
                      {emailSent === false ? (
                        <EmailSenderButton text="Send Message" color="pink" />
                      ) : (
                        <EmailConfirmationMessage />
                      )}
                    </div>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};
