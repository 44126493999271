import "./Projects.css";
import { Container, Row } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";

import annisSpaImg from "../../assets/img/Annis-Spa-Img.jpg";
import pacGirlImg from "../../assets/img/PacGirl-Img.jpg";
import optiLensImg from "../../assets/img/Opti-Lens.jpg";
import theWildOasisImg from "../../assets/img/Wild-Oasis-Img.jpg";
import fastReactPizzaImg from "../../assets/img/Fast-React-Pizza-Img.jpg";
import worldWiseImg from "../../assets/img/Worldwise-Img.jpg";
import HTML from "../../assets/img/HTML.png";
import CSS from "../../assets/img/CSS.png";
import JavaScript from "../../assets/img/JavaScript.png";
import React from "../../assets/img/React.png";
import Figma from "../../assets/img/Figma.png";

export const Projects = () => {
  const projects = [
    {
      title: "The Wild Oasis",
      skills: [
        { name: "React.js", image: React },
        { name: "Javascript", image: JavaScript },
        { name: "HTML", image: HTML },
        { name: "CSS", image: CSS },
      ],
      description: (
        <span>
          An app created to manage a hotel's operations, utilising{" "}
          <span style={{ fontWeight: 600 }}>
            React Query, React Router, Styled Components{" "}
          </span>{" "}
          and a <span style={{ fontWeight: 600 }}>back-end on Supabase</span>.
        </span>
      ),
      imgUrl: theWildOasisImg,
      codeUrl: "https://github.com/em-smyth/The-wild-oasis.git",
      projectUrl: "https://the-wild-oasis-ten-rouge.vercel.app/",
    },
    {
      title: "Worldwise",
      skills: [
        { name: "React.js", image: React },
        { name: "Javascript", image: JavaScript },
        { name: "HTML", image: HTML },
        { name: "CSS", image: CSS },
      ],
      description: (
        <span>
          An app which allows the user to keep track of their travels, by
          keeping notes of their visited places in one place. It utilises{" "}
          <span style={{ fontWeight: 600 }}>
            react-router, a geolocation API, lazy loading, the context API & CSS
            modules
          </span>
          .
        </span>
      ),
      imgUrl: worldWiseImg,
      codeUrl: "https://github.com/em-smyth/Worldwise.git",
      projectUrl: "https://worldwise-lovat.vercel.app/",
    },
    {
      title: "Fast React Pizza",
      skills: [
        { name: "React.js", image: React },
        { name: "Javascript", image: JavaScript },
        { name: "HTML", image: HTML },
        { name: "CSS", image: CSS },
      ],
      description: (
        <span>
          An app to order pizza online from a menu loaded through an{" "}
          <span style={{ fontWeight: 600 }}> API</span>.{" "}
          <span style={{ fontWeight: 600 }}>React.js </span>was used to create
          this app, alongside{" "}
          <span style={{ fontWeight: 600 }}>
            React-Router, Redux and Tailwind-CSS.
          </span>
        </span>
      ),
      imgUrl: fastReactPizzaImg,
      codeUrl: "https://github.com/em-smyth/fast-react-pizza.git",
      projectUrl: "https://fast-react-pizza-eight-mu.vercel.app/",
    },
    {
      title: "Annis Spa",
      skills: [
        { name: "HTML", image: HTML },
        { name: "CSS", image: CSS },
        { name: "JavaScript", image: JavaScript },
      ],
      description:
        "The goal was to design and build an informative website for a spa that elegantly showcases the spa's luxurious branding.",
      imgUrl: annisSpaImg,
      codeUrl: "https://github.com/em-smyth/Annis_Spa.git",
      projectUrl: "https://playful-melba-9b0cf2.netlify.app/",
    },
    {
      title: "PacGirl",
      skills: [{ name: "JavaScript", image: JavaScript }],
      description:
        "My objective was to recreate the classic Pacman arcade experience, all within a thrilling, JavaScript-driven gaming environment.",
      imgUrl: pacGirlImg,
      codeUrl: "https://github.com/em-smyth/Pacman.git",
      projectUrl: "https://sparkling-youtiao-fab892.netlify.app/",
    },
    {
      title: "UI/ UX Project",
      skills: [{ name: "Figma", image: Figma }],
      description:
        "I designed a camera brand's website with Figma, focusing on a seamless customer journey from the homepage to purchase.",
      imgUrl: optiLensImg,
      projectUrl:
        "https://www.figma.com/proto/29L997jq0xw1VgygiwCeao/Emmidna-Camera-Ecom-V1?page-id=12%3A304&type=design&node-id=129-987&viewport=423%2C267%2C0.16&t=4pzuhRxuCwriayWW-1&scaling=scale-down&starting-point-node-id=129%3A987&mode=design",
      codeUrl: "",
    },
  ];

  return (
    <Container>
      <section className="projects" id="projects">
        <h1>Projects</h1>
        <p>
          I've recently honed my skills by taking on diverse projects that
          showcase my front-end expertise. This includes building websites from
          the ground up, harnessing the power of{" "}
          <span style={{ fontWeight: 600 }}>JavaScript</span> to create dynamic
          web experiences, and utilising tools like{" "}
          <span style={{ fontWeight: 600 }}>React.js</span> and{" "}
          <span style={{ fontWeight: 600 }}>Tailwind.css</span> to craft modern,{" "}
          <span style={{ fontWeight: 600 }}>responsive</span> interfaces.
        </p>
        <Row>
          {projects.map((project, index) => {
            return <ProjectCard key={index} {...project} />;
          })}
        </Row>
      </section>
    </Container>
  );
};
