import "./Footer.css";
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import { Container, Row, Col } from "react-bootstrap";
import NavBarLogo from "../NavBar/NavBarLogo";
import navIcon1 from "../../assets/img/nav-icon1.svg";
import navIcon2 from "../../assets/img/nav-icon2.png";

export const Footer = () => {
  // Monitor hover and in view for to-top button
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <footer className="footer">
      <Container>
        <button
          ref={ref}
          // Scroll to top of page on click
          onClick={() => {
            document
              .getElementById("home")
              .scrollIntoView({ behavior: "smooth" });
          }}
          className={`to-top${isHovered ? " show" : ""} ${
            inView ? "animate" : ""
          }`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            className="bi bi-arrow-up-circle"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"
            />
          </svg>
          <p>TOP</p>
        </button>
        <Row className="align-item-center">
          <Col sm={6} className="text-center text-sm-start">
            <NavBarLogo />
          </Col>
          <Col sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a
                href="https://www.linkedin.com/in/emma-smyth-04496790/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navIcon1} alt="icon" />
              </a>
            </div>
            <div className="social-icon">
              <a
                href="https://github.com/em-smyth"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navIcon2} alt="icon" />
              </a>
            </div>
          </Col>
          <p className="copyright">
            Copyright 2024, All Rights Reserved By Emma Smyth
          </p>
        </Row>
      </Container>
    </footer>
  );
};
