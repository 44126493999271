import React from "react";
import { useInView } from "react-intersection-observer";
import { Container, Row, Col } from "react-bootstrap";
import htmlLogo from "../../assets/img/logos_html-5.svg";
import cssLogo from "../../assets/img/logos_css-3.svg";
import javascriptLogo from "../../assets/img/logos_javascript.svg";
import reactLogo from "../../assets/img/logos_react.svg";
import bootstrapLogo from "../../assets/img/logos_bootstrap.svg";
import tailwindCSSLogo from "../../assets/img/logos_tailwind.svg";
import nodeLogo from "../../assets/img/logos_node.svg";
import figmaLogo from "../../assets/img/logos_figma.svg";
import "./Skills.css";
import gitLogo from "../../assets/img/logos_git.svg";

export const Skills = () => {
  //  Monitor if over 50% of element is within view & only trigger once
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  // Delay between each animation of logos
  const logoZoomDelay = 300;

  const logosData = [
    { logo: htmlLogo, text: "HTML" },
    { logo: cssLogo, text: "CSS" },
    { logo: javascriptLogo, text: "JavaScript" },
    { logo: reactLogo, text: "React.js" },
    { logo: bootstrapLogo, text: "Bootstrap" },
    { logo: tailwindCSSLogo, text: "Tailwind.CSS" },
    { logo: nodeLogo, text: "Node.js" },
    { logo: gitLogo, text: "Git" },
    { logo: figmaLogo, text: "Figma" },
  ];

  return (
    <section className="skills" id="skills">
      <Container>
        <div className="skills-box">
          <Col>
            <Row>
              <h1>Frontend Developer</h1>
              <p>
                Hi, I'm Emma, a dedicated{" "}
                <span style={{ fontWeight: 600 }}>Frontend Developer </span>
                specialising in{" "}
                <span style={{ fontWeight: 600 }}>Website Development</span>. My
                passion lies in crafting exceptional websites and staying on the{" "}
                <span style={{ fontWeight: 600 }}>
                  cutting edge of coding technologies
                </span>
                .
              </p>
            </Row>

            <div className="logos-container">
              <div className="logos-group">
                {logosData.slice(0, 5).map((data, index) => (
                  <div
                    key={index}
                    ref={ref}
                    className={`logo-wrapper ${inView ? "in-view" : ""}`}
                    data-text={data.text}
                    style={{ animationDelay: `${index * logoZoomDelay}ms` }}
                  >
                    <img src={data.logo} alt={`Logo ${index}`} />
                  </div>
                ))}
              </div>

              {/* If add more skills, remember to update the delay value */}
              <div className="logos-group">
                {logosData.slice(5, 9).map((data, index) => (
                  <div
                    key={index}
                    ref={ref}
                    className={`logo-wrapper ${inView ? "in-view" : ""}`}
                    data-text={data.text}
                    style={{
                      animationDelay: `${(index + 5) * logoZoomDelay}ms`,
                    }}
                  >
                    <img src={data.logo} alt={`Logo ${index + 4}`} />
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </section>
  );
};
